import { call, put, takeLatest } from "redux-saga/effects";
import { FETCH_QUEUE_LIST, CREATE_QUEUE, UPDATE_QUEUE } from "./types";
import {
  fetchQueryQueuesService,
  updateQueryQue,
  updateQueryQueueService,
  updateQueryStatus,
} from "../../services";
import { removeSingleQueue, setQueus } from "./slice";
import { setAlert } from "../basicSlices";
import { handleLoadQuery } from "../query";
import { store } from "..";

function* handleLoadQueues() {
  try {
    const responseData: any = yield call(fetchQueryQueuesService);
    yield put(setQueus(responseData));
  } catch (error: any) {
    yield put(
      setAlert({
        text: error?.message,
        type: "danger",
        title: "Failed to fetch Queues!",
      })
    );
  }
}

function* handleCreateQueues({
  payload,
}: {
  payload?: { callback?: Function; query_id: string; billing_doc?: any };
} = {}): Generator<any> {
  try {
    const responseData: any = yield call(updateQueryQue, payload);
    if (payload?.query_id) {
      yield handleLoadQuery({ payload: { id: payload?.query_id } });
    }
  } catch (error: any) {
    yield put(
      setAlert({
        text: error?.message,
        type: "danger",
        title: "Failed to add query.",
      })
    );
  } finally {
    payload?.callback && payload?.callback();
  }
}

function* handleUpdateQueue({ payload }: { payload: any }): Generator<any> {
  try {
    yield put(removeSingleQueue(payload?.query_id));
    const responseData: any = yield call(updateQueryQueueService, payload);
    yield handleLoadQueues();
  } catch (error: any) {
    yield put(
      setAlert({
        text: error?.message,
        type: "danger",
        title: "Failed to update the Query Queue!",
      })
    );
  } finally {
    payload?.callback && payload?.callback();
  }
}

export default function* queueSaga() {
  yield takeLatest<any>(FETCH_QUEUE_LIST, handleLoadQueues);
  yield takeLatest<any>(CREATE_QUEUE, handleCreateQueues);
  yield takeLatest<any>(UPDATE_QUEUE, handleUpdateQueue);
}
