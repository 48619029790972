import React, { useEffect } from "react";
import Lucide from "../Lucide";
import Button from "../Button";
import { Dialog } from "../Headless";
import Tippy from "../Tippy";

const CustomDialog = ({
  buttonModalPreview,
  setButtonModalPreview,
  title,
  description,
  onSave,
  saveTitle,
  onCancel,
  isOkayBtn,
  isViewOnly,
  isTooltip,
  handleViewOnly,
  tooltipText = "",
  submit,
  cancelTitle = "Cancel",
  setLoader,
}: {
  buttonModalPreview: boolean;
  setButtonModalPreview: any;
  title?: string;
  description?: string;
  onSave?: () => void;
  saveTitle?: string;
  onCancel?: () => void;
  isOkayBtn?: boolean;
  isViewOnly?: boolean;
  isTooltip?: boolean;
  tooltipText?: string;
  handleViewOnly?: () => void;
  submit?: boolean;
  cancelTitle?: string;
  setLoader?: any;
}) => {
  return (
    <Dialog
      open={buttonModalPreview}
      onClose={() => {
        setLoader(false);
        setButtonModalPreview(false);
      }}
    >
      <Dialog.Panel className="mt-40">
        <a
          onClick={(event: React.MouseEvent) => {
            event.preventDefault();
            setButtonModalPreview(false);
            setLoader && setLoader(false);
          }}
          className="absolute top-0 right-0 mt-3 mr-3"
          href="#"
        >
          <Lucide icon="X" className="w-8 h-8 text-slate-400" />
        </a>
        <div className="p-5 text-center">
          <Lucide
            icon="AlertOctagon"
            className="w-16 h-16 mx-auto mt-3 text-warning"
          />
          {title && <div className="mt-5 text-3xl">{title}</div>}
          <div className="mt-2 text-slate-500">{description}</div>
        </div>
        {isOkayBtn ? (
          <div className="px-5 pb-8 text-center flex justify-evenly">
            <Button type="button" variant="primary" onClick={onCancel}>
              Okay
            </Button>
          </div>
        ) : (
          <div className="px-5 pb-8 text-center flex justify-evenly">
            {isViewOnly && (
              <Tippy
                as="div"
                options={{ theme: "light" }}
                content={`You can view this query, but you won't be able to make changes to the payment process. A log of your access will be recorded.`}
                key={"proceeds"}
                className="flex items-center"
              >
                <Button
                  type="button"
                  variant="primary"
                  onClick={handleViewOnly}
                >
                  View Only
                </Button>
              </Tippy>
            )}
            {onSave && !isTooltip ? (
              <Button
                type="button"
                variant="primary"
                onClick={onSave}
                loading={submit}
              >
                {saveTitle}
              </Button>
            ) : (
              onSave &&
              isTooltip && (
                <Tippy
                  as="div"
                  options={{ theme: "light" }}
                  content={tooltipText}
                  key={"proceeds"}
                  className="flex items-center"
                >
                  <Button
                    type="button"
                    variant="primary"
                    onClick={onSave}
                    loading={submit}
                  >
                    {saveTitle}
                  </Button>
                </Tippy>
              )
            )}
            {onCancel && (
              <Button
                onClick={onCancel}
                className="bg-gray-300 text-black-800 "
              >
                {cancelTitle}
              </Button>
            )}
          </div>
        )}
      </Dialog.Panel>
    </Dialog>
  );
};

export default CustomDialog;
