import React from "react";
import Button from "src/base-components/Button";
import {
  FormHelp,
  FormInput,
  FormLabel,
  FormSelect,
  InputGroup,
} from "src/base-components/Form";
import { useEffect, useState } from "react";
import SlideoverModal from "src/components/SlideoverModal";
import Lucide from "src/base-components/Lucide";
import { connect } from "react-redux";
import { sendTicket } from "src/stores/quotation";
import { RootState } from "src/stores";
import {
  emailTemplateProps,
  queryDataProps,
  sendTicketProps,
} from "src/interfaces";
import { fetchEmailTemplates } from "src/stores/emailTemplate";
import TinyMCE from "src/base-components/TinyMCE";
import { fetchQuotationTicket } from "src/services";
import { baseUrl } from "src/services/http";
import LoadingIcon from "src/base-components/LoadingIcon";
import _ from "lodash";

interface TicketItem {
  ticket?: string;
  name?: string;
  title?: string;
}

export const SendTicket = ({
  query,
  onSend,
  fetchTemplates,
  emailTemplates,
  visible,
  setVisible,
  model,
  setModel,
  quotations,
}: {
  query?: queryDataProps;
  onSend: (payload: sendTicketProps) => void;
  fetchTemplates: () => void;
  emailTemplates: emailTemplateProps;
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  model: sendTicketProps;
  setModel: React.Dispatch<React.SetStateAction<sendTicketProps>>;
  quotations: any;
}) => {
  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const name = e?.target?.name;
    const value = e?.target?.value;
    setModel({ ...model, [name]: value });
  };

  const templates = emailTemplates?.data || [];
  const query_id = query?.id || "";
  const [submitting, setSubmitting] = useState(false);
  const [ticketnames, setTicketNames] = useState<any>([]);
  const [selectedItems, setSelectedItems] = useState<any>([]);
  const [selectedQuotation, setSelectedQuotation] = useState<any>(null);
  const [superlargeSlideoverSizePreview, setSuperlargeSlideoverSizePreview] =
    useState(false);
  const [selectedTicket, setSelectedTicket] = useState<any>(null);

  const addPrefix = (path, prefix) => {
    return `${prefix}/${path}`;
  };

  function filterNamesByTicket(data, filenames) {
    const ticketToNameMap = new Map();
    data.forEach((item) => {
      const ticketName = item.ticket.split("/").pop();
      ticketToNameMap.set(ticketName, item.name);
    });
    return filenames.map(
      (filename) => ticketToNameMap.get(filename) || filename
    );
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event?.preventDefault();

    const names = filterNamesByTicket(ticketnames?.data, selectedItems);

    const greeting = `<p>Dear ${
      model?.customerName ?? query?.customer?.fullname
    },</p>`;

    const contentWithGreeting = greeting + model?.email_content;

    const payload: sendTicketProps = {
      email_content: contentWithGreeting,
      quotation_id: model?.quotation_id,
      query_id: query?.id,
      cc_email: model?.cc_email?.replaceAll(" ", ""),
      ticket: selectedItems,
      ticketNames: names,
    };
    Object.keys(payload)?.map((key) => {
      if (!payload[key]) {
        delete payload[key];
      }
    });
    setSubmitting(true);
    onSend({
      ...payload,
      callback: (error) => {
        if (!error) {
          setModel({ query_id: query_id } as any);
          setVisible(false);
        }
        setSubmitting(false);
      },
    } as any);
  };

  useEffect(() => {
    if (visible) {
      fetchTemplates();
    }
  }, [visible]);

  useEffect(() => {
    setModel((previous) => {
      return {
        ...previous,
        email_content:
          templates?.find((ele) => ele?.id === model?.template_id)?.content ||
          "",
      };
    });
  }, [model?.template_id]);

  const fetchTickets = async (quotationId: string) => {
    try {
      const response = await fetchQuotationTicket({
        quotation_id: quotationId,
      });

      if (response?.formattedTickets?.length > 0) {
        setTicketNames({ type: "new", data: response?.formattedTickets });
      } else {
        const filePaths = response?.quotation_ticket
          ? response.quotation_ticket
              .split(",")
              .map((path) => path.trim().replace(/^ticket\//, ""))
          : [];

        const tickets = filePaths.map((path, index) => ({
          name: path,
          id: index,
          ticket: path,
        }));

        if (tickets?.length > 0) {
          setTicketNames({ type: "old", data: tickets });
        }
      }
    } catch (error) {
      console.error("Error fetching tickets:", error);
    }
  };

  useEffect(() => {
    if (model?.quotation_id) {
      fetchTickets(model?.quotation_id);
    }
  }, [model]);

  const handleCheckboxChange = (itemId: any) => {
    setSelectedItems((prevSelectedItems: any) =>
      prevSelectedItems.includes(itemId)
        ? prevSelectedItems.filter((id) => id !== itemId)
        : [...prevSelectedItems, itemId]
    );
  };

  const extractTicketPart = (filename) => {
    const match = filename.match(/Ticket_.*(?=\.pdf)/);
    return match ? match[0] : null;
  };

  useEffect(() => {
    const quotation = quotations?.find(
      (item: any) => item?.id === model?.quotation_id
    );
    if (quotation) {
      setSelectedQuotation(quotation);
    }
  }, [quotations]);

  const handleViewClick = (ticket: string) => {
    setSelectedTicket(ticket);
    setSuperlargeSlideoverSizePreview(true);
  };

  return (
    <SlideoverModal
      size="lg"
      visible={visible}
      onClose={() => {
        setVisible(false);
      }}
      headerTitle="Send Ticket"
    >
      <form onSubmit={handleSubmit} className="w-full flex flex-col h-full">
        <div className="flex my-5 flex-wrap">
          {ticketnames?.data?.map((item: TicketItem) => {
            const itemId: string = item.ticket
              ? (item.ticket || "").trim().replace(/^ticket\//, "")
              : item.name || "";

            const isChecked = selectedItems.includes(itemId);
            const displayName =
              item.name && item?.name !== "null"
                ? item.name
                : extractTicketPart(item?.ticket) ?? "Attachment";

            return (
              <div key={itemId} className="inline-flex items-center gap-2">
                <label
                  className="relative flex items-center p-3 rounded-full cursor-pointer"
                  htmlFor={`checkbox-${itemId}`}
                >
                  <input
                    type="checkbox"
                    id={`checkbox-${itemId}`}
                    className="peer sr-only"
                    checked={isChecked}
                    onChange={() => handleCheckboxChange(itemId)}
                  />
                  <span className="absolute flex items-center justify-center w-5 h-5 rounded-full border border-gray-500 transition-all peer-checked:bg-blue-500 ">
                    {isChecked && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-3.5 w-3.5 text-white"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        stroke="currentColor"
                        strokeWidth="1"
                      >
                        <path
                          fillRule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clipRule="evenodd"
                        />
                      </svg>
                    )}
                  </span>
                </label>
                <span className="ml-2">
                  {displayName.length > 10
                    ? `${displayName.slice(0, 10)}..`
                    : displayName}
                </span>
                <Lucide
                  icon="View"
                  className="w-4 h-4 cursor-pointer"
                  onClick={() =>
                    handleViewClick(item.ticket || item.name || "")
                  }
                />
              </div>
            );
          })}
        </div>

        <SlideoverModal
          size="xl"
          visible={superlargeSlideoverSizePreview}
          onClose={() => {
            setSuperlargeSlideoverSizePreview(false);
          }}
          headerTitle={`Ticket-(${query?.query_no})`}
        >
          <div className="h-[100%]">
            {selectedTicket && (
              <iframe
                src={`${baseUrl}/${selectedTicket}`}
                width="100%"
                height="100%"
              />
            )}
          </div>
        </SlideoverModal>

        <div className="w-full">
          <FormLabel className="w-full">CC Emails</FormLabel>
          <FormInput
            type="text"
            name="cc_email"
            value={model?.cc_email}
            onChange={(e) =>
              setModel((previous) => {
                return {
                  ...previous,
                  cc_email: e?.target?.value,
                };
              })
            }
            className="w-full"
            placeholder="Enter emails. e.g. example1@email.com, example2@email.com"
            aria-label="CC Emails"
          />
        </div>
        <div className="w-full mt-3">
          <FormLabel className="w-full">Template</FormLabel>
          <FormSelect
            className="w-full"
            id="template_id"
            name="template_id"
            required
            value={model?.template_id}
            onChange={handleSelectChange as any}
          >
            <option value={""}>--Select Email Template--</option>
            {templates.map((template, templateKey) => (
              <option key={templateKey} value={template?.id}>
                {template.title}
              </option>
            ))}
          </FormSelect>
        </div>

        {model?.template_id && (
          <div className="mt-3">
            <FormLabel className="w-full">Email Content</FormLabel>
            <div className="border rounded-md p-2">
              <div className="flex-1 w-full">
                <InputGroup className="w-full">
                  <InputGroup.Text>Dear</InputGroup.Text>
                  <FormInput
                    name="customerName"
                    type="text"
                    required
                    onChange={(e) =>
                      setModel((previous) => {
                        return {
                          ...previous,
                          customerName: e?.target?.value,
                        };
                      })
                    }
                    value={model?.customerName ?? query?.customer?.fullname}
                  />
                </InputGroup>
              </div>

              <div className="mt-3">
                {/* <FormLabel className="w-full">Content</FormLabel> */}
                <div className="flex-1 w-full">
                  <TinyMCE
                    value={model?.email_content || ""}
                    onEditorChange={(data) =>
                      setModel((previous) => {
                        return { ...previous, email_content: data };
                      })
                    }
                  />
                  <FormHelp className="text-right">
                    Maximum characters:{" "}
                    {model?.email_content ? model.email_content.length : 0}
                    /2000
                  </FormHelp>
                </div>
              </div>
            </div>
          </div>
        )}

        <Button
          disabled={!model?.template_id}
          variant="primary"
          loading={submitting}
          className="w-full px-10 md:w-auto text-white bg-primary mt-auto ml-auto "
          type="submit"
        >
          <Lucide icon="Check" className="stroke-[1.3] w-4 h-4 mr-3" />
          Send
        </Button>
      </form>
    </SlideoverModal>
  );
};

const mapStateToProps = ({
  queries: { detail },
  emailTemplates,
}: RootState) => ({
  query: detail,
  emailTemplates,
});

const mapDispatchToProps = (dispatch: any) => ({
  onSend: (payload: sendTicketProps) => dispatch(sendTicket(payload)),
  fetchTemplates: () => dispatch(fetchEmailTemplates({ type: 1 })),
});

export default connect(mapStateToProps, mapDispatchToProps)(SendTicket);
