import { Navigate } from "react-router-dom";
import DashboardOverview from "../pages/DashboardOverview";
import Layout from "../themes";
import Login from "../pages/Authentication/Login";
import Queries from "../pages/Queries";
import AddUpdateQuery from "../pages/Queries/AddQuery";
import QueryDetail from "../pages/Queries/QueryDetail";
import Users from "../pages/UsersAndRoles/Users";
import Roles from "../pages/UsersAndRoles/Roles";
import Permissions from "src/pages/UsersAndRoles/Permissions";
import EmailTemplates from "src/pages/EmailTemplates";
import { authUserProps } from "src/interfaces";
import { selectAuthUser } from "src/stores/auth";
import { useAppSelector } from "src/stores";
import Actionable from "src/pages/Queries/Actionable";
import Customers from "src/pages/Customers";
import CustomerDetail from "src/pages/Customers/detail";
import ColdFollowUp from "src/pages/Queries/ColdFollowUp";

export const useAppRoutes = (): {
  path?: string;
  element?: any;
  title?: string | React.ReactNode;
  children?: { path?: string; element?: any; title?: string }[];
}[] => {
  let authUser: authUserProps = useAppSelector(selectAuthUser) || ({} as any);
  const isLoggedIn = !!authUser?.token;
  const userType = authUser?.user_type;
  const permissions = authUser?.role?.permissions;
  const allRoutes = !isLoggedIn
    ? []
    : [
        {
          path: "",
          title: "Home",
          element: <Layout />,
          children: [
            {
              path: "*",
              element: <Navigate to={"/"} />,
            },
            {
              title: "Analytics",
              path: "/",
              element: <DashboardOverview />,
            },
            {
              title: "Queries",
              path: "create-query",
              element: <AddUpdateQuery />,
            },
            {
              title: "Users",
              path: "users",
              element: <Users />,
            },
            {
              title: "Query",
              path: "query-detail/:id/:no?",
              element: <QueryDetail />,
            },
            {
              title: "Queries",
              path: "queries",
              element: <Queries />,
            },
            {
              title: "Queries",
              path: "search-queries",
              element: <Queries />,
            },
            {
              title: "Queries",
              path: "all",
              element: <Queries />,
            },
            {
              title: "My Actionable",
              path: "actionable",
              element: <Actionable />,
            },
            {
              title: "Email Templates",
              path: "email-templates",
              element: <EmailTemplates />,
            },
            {
              title: "Customers",
              path: "customers",
              element: <Customers />,
            },
            {
              title: "Customer Detail",
              path: "customer-detail/:id",
              element: <CustomerDetail />,
            },
          ],
        },
      ];

  let permittedRoutes: any[] = [
    {
      path: "*",
      element: <Navigate to={isLoggedIn ? "/" : "/login"} />,
    },
  ];
  if (!isLoggedIn) {
    permittedRoutes.push(
      ...[
        {
          path: "*",
          element: <Navigate to={"login"} />,
        },
        {
          title: "Login",
          path: "/login",
          element: <Login />,
          children: undefined,
        },
      ]
    );
  }

  if (isLoggedIn && userType == "Superadmin") {
    permittedRoutes.push(
      ...[
        {
          path: "",
          title: "Permissions",
          element: <Layout />,
          children: [
            {
              title: "Permissions",
              path: "/",
              element: <Permissions />,
            },
            {
              title: "Roles",
              path: "roles",
              element: <Roles />,
            },
            {
              title: "Users",
              path: "users",
              element: <Users />,
            },
          ],
        },
      ]
    );
  } else if (isLoggedIn && userType == "Admin") {
    permittedRoutes.push(
      ...[
        {
          path: "",
          title: "Home",
          element: <Layout />,
          children: [
            {
              path: "*",
              element: <Navigate to={isLoggedIn ? "/" : "login"} />,
            },
            {
              title: "Analytics",
              path: "/",
              element: <DashboardOverview />,
            },
            {
              title: "Cold Follow-up's",
              path: "cold-follow-up",
              element: <ColdFollowUp />,
            },
          ],
        },
        ...allRoutes,
      ]
    );
  } else if (isLoggedIn) {
    permittedRoutes.push(
      ...[
        {
          path: "",
          title: "Home",
          element: <Layout />,
          children: [
            {
              path: "*",
              element: <Navigate to={isLoggedIn ? "/" : "login"} />,
            },
            {
              title: "Analytics",
              path: "/",
              element: <DashboardOverview />,
            },
            {
              title: "Cold Follow-up's",
              path: "cold-follow-up",
              element: <ColdFollowUp />,
            },
          ],
        },
        ...allRoutes?.filter((ele) =>
          permissions?.some(
            (p) =>
              p.frontend_route?.replace("/", "") == ele?.path ||
              ele?.children?.some(
                (ce) => ce.path == p.frontend_route?.replace("/", "")
              )
          )
        ),
      ]
    );
  }
  return permittedRoutes;
};
