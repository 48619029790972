import { call, put, takeLatest } from "redux-saga/effects";
import { FETCH_MESSAGES, CREATE_MESSAGES, UPDATE_MESSAGES } from "./types";

import {
  createPermissionService,
  fetchPermissionsService,
  updatePermissionService,
} from "../../services";
// import { setPermissions } from "./slice";
import { setAlert } from "../basicSlices";

function* handleLoadPermissions(
  { payload }: { payload?: any } = { payload: undefined }
): Generator<any> {
  // try {
  //   let responseData: any = yield call(fetchPermissionsService, payload);
  //   if (Array.isArray(responseData)) {
  //     responseData = { data: responseData };
  //   }
  //   yield put(setPermissions(responseData));
  // } catch (error: any) {
  //   yield put(
  //     setAlert({
  //       text: error?.message,
  //       type: "danger",
  //       title: "Failed to Load Permissions!",
  //     })
  //   );
  // } finally {
  //   payload?.callback && payload?.callback();
  // }
}

function* handleCreatePermission({
  payload,
}: {
  payload: any;
}): Generator<any> {
  // try {
  //   const responseData: any = yield call(createPermissionService, payload);
  //   // yield handleLoadPermissions();
  // } catch (error: any) {
  //   yield put(
  //     setAlert({
  //       text: error?.message,
  //       type: "danger",
  //       title: "Failed to create Permission!",
  //     })
  //   );
  // } finally {
  //   payload?.callback && payload?.callback();
  // }
}

function* handleUpdatePermission({
  payload,
}: {
  payload: any;
}): Generator<any> {
  // try {
  //   const responseData: any = yield call(updatePermissionService, payload);
  //   // yield handleLoadPermissions();
  // } catch (error: any) {
  //   yield put(
  //     setAlert({
  //       text: error?.message,
  //       type: "danger",
  //       title: "Failed to update Permission!",
  //     })
  //   );
  // } finally {
  //   payload?.callback && payload?.callback();
  // }
}

export default function* permissionSaga() {
  yield takeLatest<any>(FETCH_MESSAGES, handleLoadPermissions);
  yield takeLatest<any>(CREATE_MESSAGES, handleCreatePermission);
  yield takeLatest<any>(UPDATE_MESSAGES, handleUpdatePermission);
}
