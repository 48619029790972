import { connect } from "react-redux";
import Button from "src/base-components/Button";
import _ from "lodash";
import {
  PreviewComponent,
  Preview,
} from "src/base-components/PreviewComponent";
import { FormSwitch } from "src/base-components/Form";
import { useEffect, useMemo, useState } from "react";
import {
  acceptPaymentProps,
  paymentProps,
  refundPaymentProps,
} from "src/interfaces/payment.interfaces";
import { fetchPayment } from "src/stores/payment";
import {
  alertProps,
  authUserProps,
  queryAssignToProps,
  queryDataProps,
} from "src/interfaces";
import { RootState } from "src/stores";
import moment from "moment";
import {
  camalCaseToTitle,
  checkPermission,
  dateParser,
  decrypt,
  isAdmin,
  isManager,
} from "src/helpers";
import { paymentAPIUrls } from "src/common/apiActions";
import AddUpdate from "./AddUpdate";
import Card from "src/components/Card";
import { setAlert } from "src/stores/basicSlices";
import EmergencyIcon from "../../EmergencyIcon";
import InitialRefunt from "./InitialRefunt";
import { Menu } from "src/base-components/Headless";
import Lucide from "src/base-components/Lucide";
import QueryDetail from "..";
import AssignTo from "../AssignTo";
import { isMultipleAcceptedQuotation } from "../../helpers";
import CustomDialog from "src/base-components/CustomDialog";
import { createQueryActivityLog } from "src/stores/query";

export const Payments = ({
  query,
  authUser,
  setAlert,
  onFetch,
  handleAssignToClick,
  onCreate,
}: {
  query: queryDataProps | undefined;
  authUser: authUserProps;
  setAlert: (payload: alertProps) => void;
  onFetch: (payload: any) => void;
  handleAssignToClick: Function;
  onCreate: (payload: any) => void;
}) => {
  const amount = query?.remaining_payment || "";
  const canMarkAccepted = checkPermission(paymentAPIUrls?.accept);
  const payments = query?.payment || [];
  // const [isPartial, setIsPartial] = useState(
  //   query?.is_partial == 0 ? false : true
  // );
  const [isView, setIsView] = useState(false);

  const [modalVisible, setModalVisible] = useState(false);
  const [model, setModel] = useState<paymentProps>({
    query_id: query?.id || "",
    id: "",
    payment_date: moment(new Date()).format("YYYY-MM-DD"),
    amount: "",
    actual_amount: "",
    payment_mode: "",
    is_split: 0,
    quotation_id: "",
    formatted_files: [],
    isPartial: false,
    is_partial: 0,
    remaining_payment: 0,
  });

  const [acceptModel, setAcceptModel] = useState<acceptPaymentProps | any>({
    query_id: query?.id || "",
    payment_id: "",
    total_margin: 0,
  });

  const [refundVisible, setRefundVisible] = useState("");
  const [refundModel, setRefundModel] = useState<refundPaymentProps>({
    date: moment(new Date()).format("YYYY-MM-DD"),
    payment_id: "",
    query_id: query?.id,
    amount: "",
    actual_amount: "",
    quotation_id: "",
  });

  const [loading, setLoading] = useState(false);

  const [assignToModel, setAssignToModel] = useState<queryAssignToProps>({
    id: query?.id,
  } as any);
  const [assignToModal, setAssignToModal] = useState(false);

  const [openModalAlert, setOpenModalAlert] = useState<boolean>(false);

  const [currentPayment, setCurrentPayment] = useState<any>(null);

  const handleRemainingAmount = (quotationId: any) => {
    try {
      const currentAccepted: any = query?.accepted_quotation;
      if (quotationId && currentAccepted && currentAccepted?.length > 0) {
        const slectedId = currentAccepted?.find(
          (item: any) => item?.id === quotationId
        );
        if (!_.isEmpty(slectedId)) {
          setModel((previous) => ({
            ...previous,
            amount: slectedId?.remaining_payment,
            actual_amount: slectedId?.remaining_payment,
            isPartial: slectedId?.is_partial == 0 ? false : true,
          }));
        }
      }
    } catch (error) {
      console.error("Error : ", error);
    }
  };

  const handlePyamentClick = (payment: any) => {
    setCurrentPayment(payment);
    if (
      query?.assigned_by !== authUser?.id &&
      query?.assigned_to !== authUser?.id &&
      payment?.status !== 2 &&
      query?.sourced_by !== authUser?.id
    ) {
      setOpenModalAlert(true);
    } else {
      handleRemainingAmount(payment?.quotation_id);
      setModalVisible(true);
      setIsView(true);
      handleFetchPayment(payment?.id || "", payment?.status || "");
    }
    return;
  };

  const dialogeOnSave = () => {
    setOpenModalAlert(false);
    onCreate({
      query_id: query?.id,
      title: "Query Accessed during payment process",
      description: `${authUser?.name} has accessed this query during payment processing`,
    });
    handleRemainingAmount(currentPayment?.quotation_id);
    setModalVisible(true);
    setIsView(true);
    handleFetchPayment(currentPayment?.id || "", currentPayment?.status || "");
  };

  const handleViewOnly = () => {
    setOpenModalAlert(false);
    onCreate({
      query_id: query?.id,
      title: `Query Viewed by ${authUser?.name}`,
      description: `Query was viewed by ${authUser?.name}`,
    });
  };

  const getQuotationNumber = (qoutationId: string) => {
    if (qoutationId) {
      const result = query?.quotations_detail?.find(
        (item: any) => item?.id === qoutationId
      );
      if (!_.isEmpty(result)) {
        return result?.quotation_code;
      }
    }
  };

  const handleFetchPayment = (id: string, status: string) => {
    setLoading(true);
    onFetch({
      id: id,
      callback: (error: boolean, response: any) => {
        if (!error) {
          if (canMarkAccepted) {
            setAcceptModel({
              query_id: query?.id || "",
              payment_id: response?.id,
              total_margin: response?.total_margin || 0,
              transaction_id: response?.transaction_id || "",
              status: status,
            });
          }
          let finalResponse: paymentProps = {
            ...response,
            card_number: response?.card_number
              ? decrypt(response?.card_number)
              : "",
            payment_mode: response?.payment_mode,
          };
          if (response?.payment_mode == 2) {
            finalResponse = {
              ...finalResponse,
              card_number: response?.card_number
                ? decrypt(response?.card_number)
                : "",
              card_cvv: response?.card_number
                ? decrypt(response?.card_cvv)
                : "",
            };
          }
          if (response?.payment_mode == 1) {
            finalResponse = {
              ...finalResponse,
              signer_name: response?.payment_form_detail?.signer_name,
              signer_email: response?.payment_form_detail?.signer_email,
              signer_country_code:
                response?.payment_form_detail?.signer_country_code,
              signer_phone: response?.payment_form_detail?.signer_phone,
              template_id: response?.payment_form_detail?.template_id,
              template_name: response?.payment_form_detail?.template_name,
              signed_pdf: response?.payment_form_detail?.signed_pdf,
              contract_status: response?.payment_form_detail?.contract_status,
              charge1_amount: response?.payment_form_detail?.charge1_amount,
              charge1_for: response?.payment_form_detail?.charge1_for,
              charge2_amount: response?.payment_form_detail?.charge2_amount,
              charge2_for: response?.payment_form_detail?.charge2_for,
            };
          }

          if (finalResponse?.is_partial == 1) {
            setModel({ ...finalResponse, isPartial: true });
          } else {
            setModel({ ...finalResponse, isPartial: false });
          }
        }
        setLoading(false);
      },
    });
  };

  const checkIfAsigned = (qoutationId: string) => {
    if (qoutationId) {
      const result: any = query?.quotations_detail?.find(
        (item: any) => item?.id === qoutationId
      );
      if (!_.isEmpty(result)) {
        return result;
      }
    }
  };

  const checkIfPaymentCompleted = useMemo(() => {
    const currentAccepted: any = query?.accepted_quotation;

    if (query && currentAccepted?.length > 0) {
      const payload = currentAccepted.filter(
        (item: any) =>
          item?.status_text === "Accepted" && item?.remaining_payment !== 0
      );
      if (payload && payload?.length > 0) {
        return true;
      }
      return false;
    }
  }, [query?.accepted_quotation]);

  const [openConfirmationModal, setConfirmationModal] =
    useState<boolean>(false);

  const handleNewPayment = () => {
    setModalVisible(true);
    setIsView(false);
    setConfirmationModal(false);
  };

  return (
    <div>
      <CustomDialog
        buttonModalPreview={openConfirmationModal}
        setButtonModalPreview={setConfirmationModal}
        description={
          "Would you like to update the stage of this query from Cold Follow-Up to In-Process?"
        }
        onSave={handleNewPayment}
        onCancel={() => {
          setConfirmationModal(false);
        }}
        saveTitle="Proceed anyway"
        isOkayBtn={false}
      />

      <CustomDialog
        buttonModalPreview={openModalAlert}
        setButtonModalPreview={setOpenModalAlert}
        title={`Query Already Assigned`}
        description={`This query is already assigned for payment processing to another manager. Would you like to proceed anyway or cancel?`}
        onSave={dialogeOnSave}
        onCancel={() => setOpenModalAlert(false)}
        saveTitle="Proceed anyway"
        tooltipText="You can view and access the payment process for this query. Any changes you make will be recorded."
        isOkayBtn={false}
        isViewOnly={
          query?.assigned_by !== authUser?.id &&
          query?.assigned_to !== authUser?.id
        }
        isTooltip={true}
        handleViewOnly={handleViewOnly}
      />
      <div className="text-base font-medium group-[.mode--light]:text-white mt-8 mb-2">
        Payments
      </div>
      <Card className="flex flex-col">
        <PreviewComponent>
          <Preview>
            {payments.length > 0 ? (
              payments.map((payment, index) => (
                <div
                  className={`mb-3 relative border border-dashed ${
                    payment?.type == 2 ? "border border-primary" : ""
                  } rounded-[0.6rem] shadow-sm p-4`}
                  key={index}
                >
                  <div
                    className="w-full cursor-pointer"
                    onClick={(event: React.MouseEvent) => {
                      event.preventDefault();
                      handlePyamentClick(payment);
                    }}
                  >
                    <div className={`relative w-full`}>
                      {payment?.type == 2 && (
                        <EmergencyIcon
                          className="absolute right-2 top-2"
                          content="Cancellation Charges"
                        />
                      )}
                      <div className="flex flex-col flex-wrap  justify-between items-start md:flex-row">
                        <div className="min-w-36">
                          <div className="text-slate-500">Date of Payment</div>
                          <div className="mt-1.5 font-medium text-slate-600">
                            {dateParser(payment.payment_date, "DD MMMM, YYYY")}
                          </div>
                        </div>
                        <div className="min-w-28">
                          <div className="text-slate-500">Amount</div>
                          <div className="mt-1.5 font-medium text-slate-600">
                            {payment.amount}
                          </div>
                        </div>
                        <div className="min-w-[12rem]">
                          <div className="text-slate-500">Mode</div>
                          <div className="mt-1.5 font-medium text-slate-600">
                            {payment.payment_mode_text}
                          </div>
                        </div>
                        <div className="min-w-32">
                          <div className="min-w-20">
                            <div className="text-slate-500">Status</div>
                            <div
                              className={`mt-1.5 font-medium text-slate-600`}
                            >
                              {payment?.payment_mode == "1"
                                ? camalCaseToTitle(payment?.payment_form_status)
                                : payment?.status_text}
                            </div>
                          </div>
                        </div>
                        <div className="min-w-44">
                          <div className="min-w-20">
                            <div className="text-slate-500">Quotation</div>
                            <div
                              className={`mt-1.5 font-medium text-slate-600`}
                            >
                              {getQuotationNumber(payment?.quotation_id) ?? ""}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex items-center my-3">
                    <div className="ml-auto">
                      {checkIfAsigned(payment?.quotation_id)?.action_status ==
                        2 &&
                        payment?.status_text !== "Completed" &&
                        query?.status !== 9 && (
                          <span className="px-2 h-[100%] p-0.5 bg-blue-100 text-blue-600 text-xs rounded-md border border-slate-200">
                            {
                              checkIfAsigned(payment?.quotation_id)
                                ?.action_status_text
                            }
                          </span>
                        )}
                    </div>

                    {payments?.length > 0 &&
                      query?.status !== 9 &&
                      query?.status !== 14 &&
                      query?.action_status !== 6 &&
                      // query?.assigned_by == authUser?.id &&
                      payment?.status_text !== "Completed" &&
                      checkIfAsigned(payment?.quotation_id)?.action_status !=
                        2 && (
                        <Button
                          onClick={() => {
                            // if ((query?.passenger?.length || 0) < totalPassengers &&
                            //   payments?.length > 0 &&
                            //   query?.status == 7 &&
                            //   query?.sourced_by == authUser?.id &&
                            //   query?.action_status != 2) {
                            //   setAlert({
                            //     text: 'Please add all passengers first',
                            //     swalIcon: "warning",
                            //     alertType: 'Swal'
                            //   })
                            //   return
                            // }

                            if (
                              query?.unsigned_payment &&
                              query?.payment?.filter(
                                (ele) =>
                                  ele?.payment_mode != "1" ||
                                  ele?.payment_form_detail?.contract_status
                              )?.length == 0
                            ) {
                              setAlert({
                                text: "Payment contract is pending",
                                swalIcon: "warning",
                                alertType: "Swal",
                              });
                              return;
                            }
                            localStorage?.setItem(
                              "currentPayment",
                              JSON.stringify(payment)
                            );
                            handleAssignToClick && handleAssignToClick();
                          }}
                          variant="primary"
                          type="button"
                          className="p-1.5"
                        >
                          Assign to Billing
                        </Button>
                      )}
                  </div>

                  {payment?.type != 2 &&
                    checkPermission(paymentAPIUrls?.initiateRefund) &&
                    payment?.status == "2" &&
                    query?.can_initiate_refund &&
                    payment?.refund_status != 2 && (
                      <Menu className="z-10 absolute top-3 right-[-7.5rem] min-w-[10rem]">
                        <Menu.Button
                          as={"div"}
                          onClick={() => {}}
                          className=" border-none p-0 outline-0"
                        >
                          <Lucide icon={"MoreVertical"} className="" />
                        </Menu.Button>
                        <Menu.Items
                          className="flex flex-col flex-wrap w-full lg:w-full md:w-full sm:w-full  "
                          placement="bottom-custom"
                        >
                          {payment?.refund_status == 0 ? (
                            <Menu.Item
                              className="md:w-full sm:w-full sm:text-sm md:text-sm"
                              onClick={() => {
                                setRefundVisible("initiate");
                                setRefundModel({
                                  query_id: query?.id,
                                  payment_id: payment?.id,
                                  amount: payment?.amount,
                                  actual_amount: payment?.amount,
                                  quotation_id: payment?.quotation_id,
                                  date: moment(new Date())?.format(
                                    "YYYY-MM-DD"
                                  ),
                                });
                              }}
                            >
                              Initiate Refund
                            </Menu.Item>
                          ) : (
                            (isAdmin() || isManager()) &&
                            payment?.refund_status == 1 && (
                              <Menu.Item
                                className="md:w-full sm:w-full sm:text-sm md:text-sm"
                                onClick={() => {
                                  setRefundVisible("complete");
                                  setRefundModel({
                                    query_id: query?.id,
                                    payment_id: payment?.id,
                                    amount: payment?.amount,
                                    actual_amount: payment?.amount,
                                    quotation_id: payment?.quotation_id,
                                    date: moment(new Date())?.format(
                                      "YYYY-MM-DD"
                                    ),
                                  });
                                }}
                              >
                                Complete Refund
                              </Menu.Item>
                            )
                          )}
                        </Menu.Items>
                      </Menu>
                    )}
                  {payment?.refund_status > 0 && (
                    <div className="text-warning mt-1">
                      Refund initiated on{" "}
                      <strong>
                        {dateParser(
                          payment?.refund_initiate_date,
                          "DD MMM, YYYY"
                        )}
                      </strong>{" "}
                      for{" "}
                      <i>
                        <strong>${payment?.refund_amount}</strong>
                      </i>
                      {payment?.refund_status == 2 && (
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ` and processed on <strong>${dateParser(
                              payment?.refund_initiate_date,
                              "DD MMM, YYYY"
                            )}</strong>`,
                          }}
                        />
                      )}
                      .
                    </div>
                  )}
                </div>
              ))
            ) : (
              <div className="text-lg font-medium flex justify-center text-gray-400 flex-wrap">
                <p>No payment details added yet</p>
              </div>
            )}

            {(payments.length === 0 ||
              !isMultipleAcceptedQuotation(
                payments,
                query?.accepted_quotation
              )) &&
              checkIfPaymentCompleted &&
              checkPermission(paymentAPIUrls?.create, query) &&
              parseInt(amount) > 0 && (
                <div className="flex px-3 py-1">
                  <a
                    className="flex justify-center text-primary mt-2"
                    style={{
                      cursor: "pointer",
                      marginLeft: "auto",
                      marginRight: payments.length === 0 ? "auto" : "unset",
                    }}
                    onClick={(event: React.MouseEvent) => {
                      event.preventDefault();
                      setModel({
                        amount: "",
                        query_id: query?.id || "",
                        payment_date: moment(new Date()).format("YYYY-MM-DD"),
                        isPartial: false,
                      } as any);
                      if (query?.stage == 5) {
                        setConfirmationModal(true);
                      } else {
                        handleNewPayment();
                      }
                    }}
                  >
                    +{payments.length > 0 ? " Add New" : " Add"} Payment
                  </a>
                </div>
              )}
          </Preview>
        </PreviewComponent>
      </Card>
      <AddUpdate
        isPartial={model?.isPartial}
        isView={isView}
        loading={loading}
        handleAssignToClick={handleAssignToClick}
        visible={modalVisible}
        setVisible={setModalVisible}
        model={model}
        setModel={setModel}
        acceptModel={acceptModel}
        setAcceptModel={setAcceptModel}
      />
      <InitialRefunt
        isView={false}
        loading={false}
        initiate={refundVisible == "initiate"}
        visible={!!refundVisible}
        setVisible={setRefundVisible}
        model={refundModel}
        setModel={setRefundModel}
      />
      <AssignTo
        headerTitle={"Assign Query"}
        model={assignToModel}
        setModel={setAssignToModel}
        visible={assignToModal}
        setVisible={setAssignToModal}
        withDoc={true}
      />
    </div>
  );
};

const mapStateToProps = ({ queries, authUser }: RootState) => ({
  query: queries?.detail || ({} as any),
  authUser,
});

const mapDispatchToProps = (dispatch: any) => ({
  setAlert: (payload: any) => dispatch(setAlert(payload)),
  onFetch: (payload: any) => dispatch(fetchPayment(payload)),
  onCreate: (payload: any) => dispatch(createQueryActivityLog(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Payments);
