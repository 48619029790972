import Snackbar from "src/base-components/Snackbar";
import Router from "./router";
import { useCallback, useEffect } from "react";
import { useAppSelector } from "./stores";
import { selectAuthUser } from "./stores/auth";
import { appInitializer } from "./helpers";
// import { usePushNotification } from "./firebase";
import runOneSignal, { usePushNotification } from "./onesignal";

const AppContent = () => {
  const pushNotification = usePushNotification();
  const authUser = useAppSelector(selectAuthUser);

  useEffect(() => {
    runOneSignal();
    pushNotification;
    appInitializer(authUser);
  }, []);

  const RouterCallback = useCallback(
    () => <Router />,
    [authUser?.role?.permissions?.length]
  );

  return (
    <>
      <Snackbar />
      <RouterCallback />
    </>
  );
};
export default AppContent;
