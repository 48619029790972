import React, { useEffect, useState } from "react";
import SlideoverModal from "../../../components/SlideoverModal";
import {
  FormInput,
  FormSelect,
  InputGroup,
} from "../../../base-components/Form";
import { createUpdatePermissionProps } from "src/interfaces";
import { connect } from "react-redux";
import { createPermission, updatePermission } from "src/stores/permission";
import { RootState } from "src/stores";
import Button from "src/base-components/Button";
import Lucide from "src/base-components/Lucide";

const Component = ({
  visible,
  setVisible,
  model = {} as any,
  setModel = () => {},
  onCreate,
  onUpdate,
  onCreateUpdateComplete,
}: {
  visible: boolean;
  setVisible: Function;
  model?: createUpdatePermissionProps;
  setModel?: (payload: createUpdatePermissionProps) => void;
  onCreate: (payload: createUpdatePermissionProps) => void;
  onUpdate: (payload: createUpdatePermissionProps) => void;
  onCreateUpdateComplete: Function;
}) => {
  const [loading, setLoading] = useState(false);

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    let payload: any = model;
    if (payload?.id) {
      onUpdate({
        ...payload,
        callback: (isError: boolean) => {
          setVisible(!!isError);
          setLoading(false);
          onCreateUpdateComplete && onCreateUpdateComplete(isError);
        },
      } as any);
      return;
    }
    Object.keys(payload)?.map((key) => {
      if (!payload[key]) {
        delete payload[key];
      }
    });
    onCreate({
      ...payload,
      callback: (isError: boolean) => {
        setVisible(!!isError);
        setLoading(false);
        onCreateUpdateComplete && onCreateUpdateComplete(isError);
      },
    } as any);
  };
  return (
    <SlideoverModal
      size="lg"
      headerTitle={model?.id ? "Update Permission" : "Add Permission"}
      visible={visible}
      onClose={(value) => {
        setVisible(value);
      }}
    >
      <form onSubmit={onSubmit} className="h-full w-full flex flex-col">
        <InputGroup className="sm:mt-2 sm:mr-2 md:w-full ">
          <InputGroup.Text className="w-1/4">Name</InputGroup.Text>
          <FormInput
            value={model?.name}
            required
            type="text"
            onChange={(event) => {
              setModel({
                ...model,
                name: event?.target?.value,
              });
            }}
          />
        </InputGroup>
        <InputGroup className="sm:mt-2 sm:mr-2 md:w-full ">
          <InputGroup.Text className="w-1/4">Nav Title</InputGroup.Text>
          <FormInput
            value={model?.nav_title}
            required
            type="text"
            onChange={(event) => {
              setModel({
                ...model,
                nav_title: event?.target?.value,
              });
            }}
          />
        </InputGroup>

        <InputGroup className="sm:mt-2 sm:mr-2 md:w-full ">
          <InputGroup.Text className="w-1/4 mx-auto my-auto">
            Is Nav
          </InputGroup.Text>
          <FormSelect
            onChange={(e) => {
              setModel({
                ...model,
                is_nav: e?.target?.value as any,
              });
            }}
            value={model?.is_nav}
            required={true}
            name="permissionName"
            className="mx-auto my-auto rounded-bl-none rounded-tl-none"
          >
            {[
              { label: "Yes/No?", value: "" },
              { label: "Yes", value: "Yes" },
              { label: "No", value: "No" },
            ]?.map((option, optionIndex) => (
              <option key={optionIndex} value={option?.value}>
                {option?.label}
              </option>
            ))}
          </FormSelect>
        </InputGroup>
        <InputGroup className="sm:mt-2 sm:mr-2 md: w-full ">
          <InputGroup.Text className="w-1/3">Frontend route</InputGroup.Text>
          <FormInput
            value={model?.frontend_route}
            type="text"
            onChange={(event) => {
              setModel({
                ...model,
                frontend_route: event?.target?.value,
              });
            }}
          />
        </InputGroup>
        <InputGroup className="sm:mt-2 sm:mr-2 md: w-full ">
          <InputGroup.Text className="w-1/3">Backend route</InputGroup.Text>
          <FormInput
            value={model?.backend_route}
            type="text"
            required
            onChange={(event) => {
              setModel({
                ...model,
                backend_route: event?.target?.value,
              });
            }}
          />
        </InputGroup>
        <Button
          className="w-full px-10 md:w-auto text-white bg-primary mt-auto ml-auto"
          type="submit"
          disabled={loading}
          loading={loading}
        >
          <Lucide icon="Check" className="stroke-[1.3] w-4 h-4 mr-2 -ml-2" />
          Submit
        </Button>
      </form>
    </SlideoverModal>
  );
};

const mapStateToProps = ({}: RootState) => ({});

const mapDispatchToProps = (dispatch: any) => ({
  onCreate: (payload: any) => dispatch(createPermission(payload)),
  onUpdate: (payload: any) => dispatch(updatePermission(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Component);
