import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";
import { permissionProps } from "../../interfaces";

const initialState: permissionProps = {} as any;

export const messagesSlice = createSlice({
  name: "messages",
  initialState,
  reducers: {
    setMessages: (state, action: PayloadAction<permissionProps>) => {
      return { ...state, messages: action.payload };
    },
    removeMessages: (state) => {
      return { ...state, messages: [] };
    },
    setChatCount: (state, action: PayloadAction<permissionProps>) => {
      return { ...state, chatCount: action.payload };
    },
    removeChatCount: (state) => {
      return { ...state, chatCount: null };
    },
  },
});

export const { setMessages, removeMessages, setChatCount, removeChatCount } =
  messagesSlice.actions;

export const selectPermissions = (state: RootState) => {
  return state.messages;
};

export default messagesSlice.reducer;
