import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import SlideoverModal from "src/components/SlideoverModal";
import { FormLabel } from "src/base-components/Form";
import Button from "src/base-components/Button";
import Lucide from "src/base-components/Lucide";
import ReactSelect from "src/base-components/ReactSelect";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { RootState } from "src/stores";
import { getDate } from "react-datepicker/dist/date_utils";
import { exportQueryData } from "src/services";

const Component = ({
  visible,
  setVisible,
  dataList = [],
}: {
  visible: boolean;
  setVisible: Function;
  dataList: any;
}) => {
  const [loading, setLoading] = useState(false);
  const [model, setModel] = useState<any>({});
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [startDate, setStartDate] = useState<Date | null>(null);

  const [minDates, setMinDate] = useState<any>({ start: "", end: "" });
  const [maxDates, setMaxDate] = useState<any>({
    start: new Date(),
    end: new Date(),
  });

  const resetInitialData = () => {
    setEndDate(null);
    setStartDate(null);
    setModel({});
    setMaxDate({
      start: new Date(),
      end: new Date(),
    });
    setMinDate({ start: "", end: "" });
  };

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      setLoading(true);
      const data: any = await exportQueryData({
        agent: model?.agent ?? null,
        startDate: startDate ? moment(startDate)?.format("YYYY-MM-DD") : null,
        endDate: endDate ? moment(endDate)?.format("YYYY-MM-DD") : null,
        agentName: model?.agent
          ? dataList?.find((item: any) => item?.val == model?.agent)
          : null,
      });
      if (data) {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log("Error while exporting: ", error);
    }
  };

  useEffect(() => {
    console.log("Loading: ", loading);
  }, [loading]);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event?.target?.name;
    const value = event?.target?.value;
    setModel({
      ...model,
      [name]: value,
    });
  };

  useEffect(() => {
    if (startDate || endDate) {
      const start = moment(startDate);
      const end = moment(endDate);

      if (end.isValid() && start.isValid()) {
        // Ensure end date is not greater than start date
        if (start.isAfter(end)) {
          setStartDate(null);
          setEndDate(null);
        }
      }
    }
  }, [startDate, endDate]);
  const selectionList = [
    { label: "Agents", val: 0 },
    { label: "Date Range", val: 1 },
  ];

  return (
    <SlideoverModal
      size="md"
      headerTitle={"Export Queries"}
      visible={visible}
      onClose={(value) => {
        resetInitialData();
        setVisible(value);
      }}
    >
      <form onSubmit={onSubmit} className="flex flex-col">
        <div className="w-full">
          <FormLabel>Formatted By</FormLabel>
          <ReactSelect
            options={selectionList?.map((item) => ({
              ...item,
              value: item?.val,
            }))}
            required
            placeholder={"Select"}
            value={model?.formatedBy}
            name="formatedBy"
            onChange={(_, value) => {
              setEndDate(null);
              setStartDate(null);

              setModel({
                ...model,
                formatedBy: value,
                agent: null,
              });
            }}
            className="min-w-36"
          />
        </div>

        {model?.formatedBy == 0 && (
          <div className="w-full mt-3">
            <FormLabel>Select Agent</FormLabel>
            <ReactSelect
              options={dataList?.map((item) => ({
                ...item,
                value: item?.val,
              }))}
              required={model?.formatedBy == 0}
              placeholder={"Agent"}
              value={model?.agent}
              name="agent"
              onChange={(_, value) => {
                setModel({
                  ...model,
                  agent: value,
                });
              }}
              className="min-w-36"
            />
          </div>
        )}

        {(model?.formatedBy === 1 || model?.formatedBy === 0) && (
          <div className="w-full mt-3">
            <FormLabel>Select Range</FormLabel>
            <div className="flex justify-between">
              <div className="w-48">
                <DatePicker
                  className="border border-[#CBD5E1] rounded-md leading-5 text-sm"
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  required={model?.formatedBy === 1}
                  maxDate={maxDates?.start}
                  minDate={minDates?.start}
                  placeholderText="Start Date"
                />
              </div>
              <div className="w-48">
                <DatePicker
                  className="border border-[#CBD5E1] rounded-md leading-5 text-sm"
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  required={model?.formatedBy === 1}
                  maxDate={maxDates?.end}
                  minDate={minDates?.end}
                  placeholderText="End Date"
                />
              </div>
            </div>
          </div>
        )}

        <div className="absolute bottom-0 right-0 py-5 px-7">
          <Button
            className="w-full px-10 md:w-auto text-white bg-primary mt-auto ml-auto"
            type="submit"
            disabled={loading}
            loading={loading}
          >
            <Lucide icon="Check" className="stroke-[1.3] w-4 h-4 mr-2 -ml-2" />
            Submit
          </Button>
        </div>
      </form>
    </SlideoverModal>
  );
};

const mapStateToProps = ({ roles, locations }: RootState) => ({});

const mapDispatchToProps = (dispatch: any) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
