import { useNavigate } from "react-router-dom";
import DataTable from "src/components/DataTable";
import { tableFields } from "./constants";
import { connect } from "react-redux";
import { RootState } from "src/stores";
import { fetchColdFollowUpQueries } from "src/stores/query";
import { customerDataProps, queryDataProps, queryProps } from "src/interfaces";
import ViewCustomer from "../../Customers/AddUpdate";
import { useEffect, useState } from "react";
import { queryAPIUrls } from "src/common/apiActions";
import LoadingIcon from "src/base-components/LoadingIcon";

function Component({
  queries,
  fetchData,
}: {
  queries: queryProps;
  fetchData: any;
}) {
  const [isLoading, setLoading] = useState<boolean>(false);
  const renderData = queries?.coldQueries?.data || [];
  const navigate = useNavigate();

  const onQueryClick = (item: queryDataProps) => {
    navigate("/query-detail/" + item?.id);
  };
  const [customer, setCustomer] = useState<customerDataProps | null>(null);
  const onCustomerClick = (item: queryDataProps) => {
    setCustomer(item?.customer);
  };

  useEffect(() => {
    try {
      setLoading(true);
      fetchData();
    } catch (error) {
      console.log("ERROR: ", error);
    }
  }, []);

  useEffect(() => {
    if (renderData?.length > 0) {
      setLoading(false);
    }
  }, [renderData]);

  useEffect(() => {
    const loaderTimer = setTimeout(() => {
      setLoading(false);
    }, 3000);

    return () => {
      clearTimeout(loaderTimer);
    };
  }, []);

  return (
    <>
      {isLoading ? (
        <div className="flex w-[100%] justify-center py-8">
          <LoadingIcon
            color="#64748b"
            icon="oval"
            className="w-8 h-8 mx-auto"
          />
        </div>
      ) : (
        <DataTable
          showColors={false}
          tableHeading="Cold Follow-up's"
          searchable={false}
          showHeader={false}
          columns={tableFields(
            (item: queryDataProps) => {
              onQueryClick(item);
            },
            (item: queryDataProps) => {
              onCustomerClick(item);
            }
          )}
          data={renderData}
        />
      )}
      <ViewCustomer
        model={customer as any}
        isView={true}
        setVisible={() => setCustomer(null)}
        visible={!!customer}
      />
    </>
  );
}

const mapStateToProps = ({ queries, authUser }: RootState) => ({
  queries,
  authUser,
});

const mapDispatchToProps = (dispatch: any) => ({
  fetchData: () => dispatch(fetchColdFollowUpQueries()),
});
export default connect(mapStateToProps, mapDispatchToProps)(Component);
