import React, { useState } from "react";
import Select, { ActionMeta, InputActionMeta } from "react-select";
import "./style.css";
import ErrorMessage from "../FormErrorValidation/ErrorMessage";
import LoadingIcon from "../LoadingIcon";

interface ReactSelectProps<Option = unknown> {
  options: Option[];
  name?: string;
  onChange?: (
    selectedOptions: Option | Option[] | null,
    value: string | string[] | null,
    actionMeta: ActionMeta<Option>
  ) => void;
  onInputChange?: (
    value: string | string[] | null,
    actionMeta: InputActionMeta
  ) => void;
  onFocus?: Function;
  value?: Option | Option[] | null;
  isMulti?: boolean;
  disabled?: boolean;
  required?: boolean;
  placeholder?: string;
  className?: string;
  containerClassName?: string;
  errors?: any;
  loading?: boolean;
  loadingOptions?: boolean;
  isSearchable?: boolean;
}

const ReactSelect: React.FC<ReactSelectProps> = ({
  options,
  name,
  onChange,
  onInputChange,
  onFocus,
  value,
  isMulti = false,
  disabled = false,
  required = false,
  placeholder = "",
  className,
  containerClassName,
  errors,
  loading = false,
  loadingOptions = false,
  isSearchable = true,
}) => {
  const [focused, setFocused] = useState(false);
  const finalValue =
    value == undefined
      ? ""
      : isMulti && Array.isArray(value)
      ? options?.filter((ele: any) => (value as any)?.includes(ele?.value))
      : options?.find((ele: any) => (value as any) == ele?.value);

  return (
    <div
      onClick={() => !focused && setFocused(true)}
      className={` rounded-md relative ${containerClassName}`}
    >
      {loading ? (
        <div
          className={`flex py-[0.20rem] w-[100%] justify-center items-center`}
        >
          <LoadingIcon
            color="#64748b"
            icon="oval"
            className={`w-8 h-8 mx-auto`}
          />
        </div>
      ) : (
        <Select
          isSearchable={isSearchable}
          options={options}
          onChange={(newValue, actionMeta) => {
            if (onChange) {
              let value: string | string[] = "" || [];
              if (isMulti) {
                value = (newValue as any[])?.map((item) => item?.value);
              } else {
                value = (newValue as any)?.value;
              }
              onChange(newValue, value, actionMeta);
            }
          }}
          onInputChange={(value, meta) =>
            onInputChange && onInputChange(value, meta)
          }
          onFocus={onFocus as any}
          isLoading={loadingOptions}
          loadingMessage={() => <span>Loading...</span>}
          name={name}
          isDisabled={disabled}
          required={required}
          onBlur={() => focused && setFocused(false)}
          value={finalValue}
          isMulti={isMulti}
          placeholder={placeholder}
          theme={(theme) => ({
            ...theme,
            borderRadius: 0,
            colors: {
              ...theme.colors,
              primary: "#233580",
            },
          })}
          className={`transition duration-200 ease-in-out w-full text-sm border-slate-300/100 shadow-sm !rounded-md placeholder:text-slate-400/90 ${
            focused &&
            "ring-primary ring-opacity-20 border-primary border-opacity-4 ring-4"
          } ${className || ""}`}
        />
      )}
      {errors && <ErrorMessage message={errors} />}
    </div>
  );
};

export default ReactSelect;
