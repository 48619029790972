import { connect } from "react-redux";
import Button from "src/base-components/Button";
import _ from "lodash";
import { FormLabel, FormTextarea } from "src/base-components/Form";
import React, { useState } from "react";
import Lucide from "src/base-components/Lucide";
import SlideoverModal from "src/components/SlideoverModal";
import { queryDataProps } from "src/interfaces";
import { RootState } from "src/stores";
import { updateStatus } from "src/stores/query";

const Component = ({
  query,
  onStatusChange,
  visible,
  setVisible,
  model,
  setModel,
}: {
  query: queryDataProps | undefined;
  onStatusChange: (payload: any) => void;
  visible: boolean;
  setVisible: Function;
  model: any;
  setModel: Function;
}) => {
  const query_id = query?.id || "";
  const [submitting, setSubmitting] = useState(false);

  const handleSelectChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e?.target?.name;
    const value = e?.target?.value;
    setModel({ ...model, [name]: value });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event?.preventDefault();
    let payload: any = {
      query_id: query_id || model?.id,
      stage: 5,
      remark: model?.remark,
    };
    if ((model as any)?.status) {
      payload = { ...payload, status: (model as any)?.status } as any;
    }
    setSubmitting(true);
    onStatusChange({
      ...payload,
      callback: (error) => {
        if (!error) {
          setModel({ query_id: query_id } as any);
          setVisible(false);
        }
        setSubmitting(false);
      },
    } as any);
  };

  return (
    <SlideoverModal
      size="md"
      visible={visible}
      onClose={() => {
        setVisible(false);
      }}
      headerTitle="Cold Follow-up"
    >
      <form className="h-[100%] w-full flex flex-col" onSubmit={handleSubmit}>
        <div className="text-base font-medium group-[.mode--light]:text-white hidden md:block mb-5">
          Query: <span className="text-primary"># {query?.query_no}</span>
        </div>

        <div className="flex-1 w-full mt-3 mb-10">
          <FormLabel>Remarks</FormLabel>
          <FormTextarea
            rows={5}
            value={model?.remark || ""}
            name="remark"
            required
            onChange={handleSelectChange as any}
          />
        </div>

        <Button
          variant="primary"
          type="submit"
          loading={submitting}
          className="w-full px-10 md:w-auto text-white bg-primary mt-auto ml-auto"
        >
          <Lucide icon="Check" className="stroke-[1.3] w-4 h-4 mr-3" />
          Submit
        </Button>
      </form>
    </SlideoverModal>
  );
};

const mapStateToProps = ({ queries: { detail }, users }: RootState) => ({
  query: detail,
});

const mapDispatchToProps = (dispatch: any) => ({
  onStatusChange: (payload: any) => dispatch(updateStatus(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
