import moment from "moment";
import "moment-timezone";
import {
  customerAPIUrls,
  passengerAPIUrls,
  paymentAPIUrls,
  queryAPIUrls,
  quotationAPIUrls,
} from "src/common/apiActions";
import { cityAirportDictionaryProps, queryDataProps } from "src/interfaces";
import { store } from "src/stores";

export function isEmpty(str: string) {
  return !str || 0 === str.length;
}

export function isBlank(str: string) {
  return !str || /^\s*$/.test(str);
}
export const camalCaseToTitle = (text: string) => {
  const result = text?.replace(/([A-Z])/g, " $1");
  const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
  return finalResult;
};

export function formatCardNumber(value: string) {
  var v = value?.replace(/\s+/g, "")?.replace(/[^0-9]/gi, "");
  var matches = v?.match(/\d{4,16}/g);
  var match: string = (matches && matches[0]) || "";
  var parts: any[] = [];
  for (let i = 0, len = match.length; i < len; i += 4) {
    parts.push(match.substring(i, i + 4));
  }
  if (parts.length) {
    return parts.join("-");
  } else {
    return value;
  }
}

export function formatExpiryDate(value: string) {
  var v = value?.replace(/\s+/g, "")?.replace(/[^0-9]/gi, "");
  var matches = v?.match(/\d{2,6}/g);
  var match = (matches && matches[0]) || "";
  var parts: any[] = [];
  if (match?.length > 2) {
    parts[0] = match.substring(0, 2);
    parts[1] = match.substring(2, match.length);
  }
  if (value?.length === 3 && value?.includes("/")) {
    return match?.substring(0, 2);
  }
  if (parts.length) {
    return parts.join("/");
  } else {
    return value;
  }
}

export function formatCVV(value: string) {
  var v = value?.replace(/\s+/g, "")?.replace(/[^0-9]/gi, "");
  return v;
}

export const addTax = (priceData: any): any => {
  let tax = 5;
  return {};
};

export const convertToForm = (payload: any): FormData => {
  const keys = Object.keys(payload);
  let formData = new FormData();
  keys.map((key) => {
    formData.append(key, payload[key]);
  });
  return formData;
};

export const convertToFormMultipleFiles = (payload: any) => {
  const formData = new FormData();

  Object.keys(payload).forEach((key) => {
    const value = payload[key];
    if (Array.isArray(value)) {
      value.forEach((item, index) => {
        Object.keys(item).forEach((subKey) => {
          const itemValue = item[subKey];
          if (itemValue instanceof File || itemValue instanceof Blob) {
            formData.append(`${key}[${index}][${subKey}]`, itemValue);
          } else {
            formData.append(`${key}[${index}][${subKey}]`, itemValue);
          }
        });
      });
    } else if (value instanceof File || value instanceof Blob) {
      formData.append(key, value);
    } else {
      formData.append(key, value);
    }
  });

  return formData;
};

export const currencyFormat = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "INR",
});

export const dateParser = (date: Date | string | number, format?: string) => {
  const parsedDate = moment(date);

  const currentTimeMST = parsedDate.format(format ?? "YYYY-MM-DD");

  return currentTimeMST;
};

const adminSkipByPass = [
  queryAPIUrls?.create,
  queryAPIUrls?.update,
  queryAPIUrls?.cancellation,
  queryAPIUrls?.changeDate,
  queryAPIUrls?.rescheduledByAirline,
  paymentAPIUrls?.initiateCancellation,
  queryAPIUrls?.assign,
  quotationAPIUrls?.create,
  quotationAPIUrls?.send,
  quotationAPIUrls?.accept,
  paymentAPIUrls?.create,
  passengerAPIUrls?.create,
  passengerAPIUrls?.update,
];

const managerSkipByPass = [
  queryAPIUrls?.create,
  queryAPIUrls?.update,
  queryAPIUrls?.assign,
  queryAPIUrls?.cancellation,
  queryAPIUrls?.changeDate,
  queryAPIUrls?.rescheduledByAirline,
  paymentAPIUrls?.initiateCancellation,
  queryAPIUrls?.forceClose,
  quotationAPIUrls?.create,
  quotationAPIUrls?.send,
  quotationAPIUrls?.accept,
  paymentAPIUrls?.create,
  passengerAPIUrls?.create,
  passengerAPIUrls?.update,
  customerAPIUrls?.create,
  customerAPIUrls?.update,
];

export const checkPermission = (
  permissionUrl: string,
  query?: queryDataProps
) => {
  const sourced_by = query?.sourced_by;
  const assigned_to = query?.assigned_to;
  const authUser = store?.getState()?.authUser;
  const permissions = authUser?.role?.permissions?.map(
    (item) => item?.backend_route
  );
  if (authUser?.user_type === "Superadmin") {
    return true;
  }
  if (authUser?.user_type === "Admin") {
    return true;
  }
  if (authUser?.role?.user_type === "Manager") {
    if (permissions?.includes(permissionUrl)) {
      return true;
    }
  }
  if (
    assigned_to &&
    authUser?.id == assigned_to &&
    query?.action_status == 1 &&
    permissions?.includes(permissionUrl)
  ) {
    return true;
  }
  if (sourced_by && authUser?.id != sourced_by) {
    return false;
  }
  return permissions?.includes(permissionUrl);
};

export const isManager = () => {
  try {
    const authUser = store?.getState()?.authUser;
    if (authUser?.role?.user_type === "Manager") {
      return true;
    }
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const isSuperAdmin = () => {
  try {
    const authUser = store?.getState()?.authUser;
    if (authUser?.user_type === "Superadmin") {
      return true;
    }
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const isAdmin = () => {
  try {
    const authUser = store?.getState()?.authUser;
    if (authUser?.user_type === "Admin") {
      return true;
    }
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const isManagerOrAdmin = () => {
  try {
    const authUser = store?.getState()?.authUser;
    if (authUser?.role?.user_type === "Non-Customer") {
      return false;
    } else {
      return true;
    }
  } catch (error) {
    console.error(error);
    return true;
  }
};

export const getCityAirportName = (
  cityAirportDictionaries: cityAirportDictionaryProps,
  iataCode: string,
  type: "A" | "C" = "A"
) => {
  const cityAirportData = cityAirportDictionaries[type + iataCode]?.data;
  // let fullName: string = `${cityAirportDictionaries[type + iataCode]?.data?.name || iataCode
  //   } Airport (${iataCode})`;
  // if (!fullName?.includes(cityAirportData?.address?.cityName)) {
  //   fullName = `${cityAirportData?.address?.cityName || ""} ${fullName}`;
  // }
  return {
    ...cityAirportData,
    fullName:
      (cityAirportDictionaries[type + iataCode]?.data?.name || "") +
      ` (${iataCode})`,
  };
};

export const customColorGenerator = (() => {
  const colorMap = {};

  const generateRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const getContrastColor = (hexColor) => {
    const r = parseInt(hexColor.substr(1, 2), 16);
    const g = parseInt(hexColor.substr(3, 2), 16);
    const b = parseInt(hexColor.substr(5, 2), 16);
    const luminance = (0.2126 * r + 0.7152 * g + 0.0722 * b) / 255;
    return luminance > 0.5 ? "#000000" : "#FFFFFF";
  };

  return (input) => {
    if (!colorMap[input]) {
      const generatedColor = generateRandomColor();
      colorMap[input] = {
        backgroundColor: generatedColor,
        color: getContrastColor(generatedColor),
      };
    }
    return colorMap[input];
  };
})();

const getTableColorClass = {
  pending: "bg-[#FF0000]",
  payment: "bg-[#E0FBE2]",
  done: "bg-[#9DDE8B]",
  quotation: "bg-[#A0DEFF]",
  reopen: "bg-[#FFAF61]",
  cancelled: "bg-[#FB9AD1]",
  inProcess: "bg-[#233580]",
  assign: "bg-[#FFFF00]",
  cold: "bg-[#8F8BDD]",
};

export const getTableColorFromStatus = {
  1: getTableColorClass["pending"],
  2: getTableColorClass["quotation"],
  4: getTableColorClass["quotation"],
  5: getTableColorClass["quotation"],
  6: getTableColorClass["payment"],
  7: getTableColorClass["payment"],
  8: getTableColorClass["payment"],
  9: getTableColorClass["done"],
  10: getTableColorClass["quotation"],
  11: getTableColorClass["cancelled"],
  14: getTableColorClass["cancelled"],
  16: getTableColorClass["reopen"],
  18: getTableColorClass["inProcess"],
  19: getTableColorClass["assign"],
  20: getTableColorClass["cold"],
};

const getColorClass = {
  pending: "before:bg-[#FF0000]",
  payment: "before:bg-[#E0FBE2]",
  done: "before:bg-[#9DDE8B]",
  quotation: "before:bg-[#A0DEFF]",
  reopen: "before:bg-[#FFAF61]",
  cancelled: "before:bg-[#FB9AD1]",
  inProcess: "before:bg-[#233580]",
  assign: "before:bg-[#FFFF00]",
  cold: "before:bg-[#8F8BDD]",
};

export const getColorFromStatus = {
  1: getColorClass["pending"],
  2: getColorClass["quotation"],
  4: getColorClass["quotation"],
  5: getColorClass["quotation"],
  6: getColorClass["payment"],
  7: getColorClass["payment"],
  8: getColorClass["payment"],
  9: getColorClass["done"],
  10: getColorClass["quotation"],
  11: getColorClass["cancelled"],
  14: getColorClass["cancelled"],
  16: getColorClass["reopen"],
  18: getColorClass["inProcess"],
  19: getColorClass["assign"],
  20: getColorClass["cold"],
};

export const showColorsTips = [
  {
    code: "bg-[#FF0000]",
    name: "New Query",
  },
  {
    code: "bg-[#233580]",
    name: "Follow Up",
  },
  {
    code: "bg-[#8F8BDD]",
    name: "Cold Follow-up's",
  },
  {
    code: "bg-[#9DDE8B]",
    name: "Action Done",
  },
  {
    code: "bg-[#FFFF00]",
    name: "Assign To Billing",
  },
  {
    code: "bg-[#FFAF61]",
    name: "Reopened Queries",
  },
  {
    code: "bg-[#FB9AD1]",
    name: "Cancelled Queries",
  },
];

export const separateDateTime = (timestamp: string, timezone: string) => {
  // Parse the timestamp into a moment object with the specified timezone
  let dateObj = moment(timestamp).tz(timezone);

  let year = dateObj.format("YYYY");
  let month = dateObj.format("MM");
  let day = dateObj.format("DD");

  let hours = dateObj.format("HH");
  let minutes = dateObj.format("mm");

  let datePart = `${year}-${month}-${day}`;
  let timePart = `${hours}:${minutes}`;

  return {
    date: datePart,
    time: timePart,
  };
};

export const checkAndModifyImageUrl = (url: string) => {
  if (url.includes("/images/airlines/")) {
    return url;
  } else {
    let parts = url.split("/");
    let airlinesIndex = parts.findIndex((part) => part === "airlines");
    parts.splice(airlinesIndex, 0, "images");
    let modifiedUrl = parts.join("/");
    return modifiedUrl;
  }
};
