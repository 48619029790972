import { connect } from "react-redux";
import Button from "src/base-components/Button";
import _ from "lodash";
import { FormLabel, FormTextarea } from "src/base-components/Form";
import React, { useState } from "react";
import Lucide from "src/base-components/Lucide";
import SlideoverModal from "src/components/SlideoverModal";
import {
  queryDataProps,
  userDataProps,
  forceDeleteProps,
} from "src/interfaces";
import { RootState } from "src/stores";
import { forceCloseQuery } from "src/stores/query";
import { fetchUsers } from "src/stores/user";
import ReactSelect from "src/base-components/ReactSelect";

const forceCloseOptions = [
  { label: "Customer Refused", val: "Customer Refused" },
  { label: "Other", val: "Other" },
];

const Component = ({
  query,
  onDelete,
  visible,
  setVisible,
  model,
  setModel,
}: {
  query: queryDataProps | undefined;
  users: userDataProps[];
  fetchUsers: (payload: { type: 1 | 2 | 3 }) => void;
  onDelete: (payload: forceDeleteProps) => void;
  visible: boolean;
  setVisible: Function;
  model: forceDeleteProps;
  setModel: Function;
}) => {
  const query_id = query?.id || "";
  const [submitting, setSubmitting] = useState(false);
  const [reason, setReason] = useState("");

  const handleSelectChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e?.target?.name;
    const value = e?.target?.value;
    setModel({ ...model, [name]: value });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event?.preventDefault();
    let payload: forceDeleteProps = {
      id: query_id || model?.id,
      remark: model?.remark,
      reason: reason,
    };
    if ((model as any)?.status) {
      payload = { ...payload, status: (model as any)?.status } as any;
    }
    setSubmitting(true);
    onDelete({
      ...payload,
      callback: (error) => {
        if (!error) {
          setModel({ query_id: query_id } as any);
          setVisible(false);
        }
        setSubmitting(false);
      },
    } as any);
  };

  const onReasonChange = (value: any) => {
    setReason(value);
  };

  return (
    <SlideoverModal
      size="md"
      visible={visible}
      onClose={() => {
        setVisible(false);
        setReason("");
      }}
      headerTitle="Force Close"
    >
      <form className="h-[100%] w-full flex flex-col" onSubmit={handleSubmit}>
        <div className="text-base font-medium group-[.mode--light]:text-white hidden md:block mb-5">
          Query: <span className="text-primary"># {query?.query_no}</span>
        </div>
        {forceCloseOptions?.length > 0 && (
          <div className="w-full">
            <FormLabel>Reason</FormLabel>
            <ReactSelect
              options={forceCloseOptions?.map((item) => {
                return { ...item, value: item?.val };
              })}
              required
              placeholder={"Select Reason"}
              value={reason}
              onChange={(_, value) => {
                onReasonChange(value as any);
              }}
            />
          </div>
        )}
        {reason && (
          <div className="flex-1 w-full mt-3 mb-10">
            <FormLabel>Remarks</FormLabel>
            <FormTextarea
              rows={5}
              value={model?.remark || ""}
              name="remark"
              required
              onChange={handleSelectChange as any}
            />
          </div>
        )}
        <Button
          variant="primary"
          type="submit"
          loading={submitting}
          className="w-full px-10 md:w-auto text-white bg-primary mt-auto ml-auto"
        >
          <Lucide icon="Check" className="stroke-[1.3] w-4 h-4 mr-3" />
          Submit
        </Button>
      </form>
    </SlideoverModal>
  );
};

const mapStateToProps = ({ queries: { detail }, users }: RootState) => ({
  query: detail,
  users: users?.data || [],
});

const mapDispatchToProps = (dispatch: any) => ({
  fetchUsers: (payload: any) => dispatch(fetchUsers(payload)),
  onDelete: (payload: any) => dispatch(forceCloseQuery(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
